import React, { useEffect, useState } from "react";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-s-square.svg";
//@ts-ignore
import bgGrid from "../../../assets/images/grid-background.png";
import SButton from "../../../design-system/SButton";
import SInput from "../../../design-system/SInput";
import { useSignIn } from "../../../api/auth/signIn/post";
import { getQuery } from "../../../hooks/functions/queries";
import { useGetInviteInfoByCode } from "../../../api/invitations/invitationByCode/get";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "../../../api/auth/signUp/post";
import { motion, AnimatePresence } from "framer-motion";

interface ISignInForm {
  className?: string;
}

const FORM_STATES = {
  INITIAL: "INITIAL",
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
} as const;

function Form({ className }: ISignInForm) {
  const { mutateAsync: signIn, isPending: isSignInPending } = useSignIn();
  const { mutateAsync: signUp, isPending: isSignUpPending } = useSignUp();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [error, setError] = useState("");
  const [formState, setFormState] = useState<
    (typeof FORM_STATES)[keyof typeof FORM_STATES]
  >(FORM_STATES.INITIAL);

  const navigate = useNavigate();

  const invitationCodeGlobal = getQuery("isGlobal");
  const invitationCode = !invitationCodeGlobal ? getQuery("code") : null;
  const packageId = getQuery("package");
  const canvaUserToken = getQuery("canva_user_token");

  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [isGlobalInvited, setIsGlobalInvited] = useState<boolean>(false);

  const { data: invitationByCode } = useGetInviteInfoByCode({
    code: invitationCode,
  });

  const resetForm = () => {
    setFormState(FORM_STATES.INITIAL);
    setError("");
    setPassword("");
    setName("");
  };

  const handleEmailCheck = async () => {
    try {
      await signIn({ email, password: "" });
      setFormState(FORM_STATES.LOGIN);
    } catch (err: any) {
      const userExists = err.response?.data?.data?.userExists;
      if (userExists === false) {
        setFormState(FORM_STATES.SIGNUP);
      } else if (userExists === true) {
        setFormState(FORM_STATES.LOGIN);
      } else {
        setError(
          err.response?.data?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };

  const handleLogin = async () => {
    try {
      await signIn({ email, password });
    } catch (err: any) {
      if (err.response?.status === 401) {
        window.location.href = err.response.data.message;
      } else {
        setError(err.response?.data?.message || "Invalid email or password");
      }
    }
  };

  const handleSignup = async () => {
    try {
      const res = await signUp({
        email,
        password,
        name,
        packageId: Number(packageId) || null,
        isInvited: isInvited,
        addSubscription: true,
      });

      if (res.status === 201 && res.data.isSuccess) {
        localStorage.setItem("token", res.data.data.token);
        if (canvaUserToken) {
          navigate(`/canva-redirect${window.location?.search}`);
        } else if (isGlobalInvited) {
          navigate(`/globalInvite${window.location?.search}`);
        } else if (isInvited) {
          navigate(`/invite${window.location?.search}`);
        } else {
          navigate("/");
        }
      }
    } catch (err: any) {
      setError(
        err.response?.data?.message || "An error occurred during signup"
      );
    }
  };

  const handleSubmit = async () => {
    setError("");

    if (!validateForm()) return;

    if (isInitialState) {
      await handleEmailCheck();
    } else if (isLoginState) {
      await handleLogin();
    } else {
      await handleSignup();
    }
  };

  useEffect(() => {
    if (invitationCodeGlobal && getQuery("isGlobal") === "true") {
      setIsGlobalInvited(true);
    }
    if (invitationByCode) {
      setEmail(invitationByCode?.data.userEmail);
      setIsInvited(true);
    }
  }, [invitationByCode]);

  useEffect(() => {
    if (getQuery("email") && !invitationByCode && isInitialState) {
      resetForm();
      if (!email) setEmail(getQuery("email") as string);
      handleSubmit();
    }
  }, [email]);

  const handleGoogleLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    window.location.href = `${
      process.env.REACT_APP_API_ENDPOINT
    }auth/external-login?provider=Google${
      searchParams.toString() ? `&${searchParams.toString()}` : ""
    }`;
  };

  const isInitialState = formState === FORM_STATES.INITIAL;
  const isLoginState = formState === FORM_STATES.LOGIN;
  const isSignupState = formState === FORM_STATES.SIGNUP;

  const validateEmail = (email: string) => {
    if (!email) {
      return "Please enter your email";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return null;
  };

  const validateForm = () => {
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      return false;
    }

    if (!isInitialState && !password) {
      setError("Please enter your password");
      return false;
    }

    if (isSignupState && !name) {
      setError("Please enter your name");
      return false;
    }

    return true;
  };

  const fadeIn = {
    initial: { opacity: 0, y: 5 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 5 },
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className={`${className} flex flex-col items-center justify-center max-w-[380px] w-full min-h-[500px]`}
    >
      <img
        className="absolute z-[-1] top-[-10%] w-[50%] h-[300px] object-cover"
        src={bgGrid}
        alt="bg"
      />
      <img
        alt={"logo"}
        src={logo}
        className="w-[48px] h-[48px] min-h-[48px] min-w-[48px]"
      />
      <motion.p
        variants={fadeIn}
        initial="initial"
        animate="animate"
        className="display-sm-sb text-gray-900 text-center mt-[20px]"
      >
        {isSignupState ? "Welcome to STORI" : "Welcome Back!"}
      </motion.p>
      <p className="text-md text-gray-600 mt-[6px] text-center">
        {isInitialState
          ? "Continue by entering your email below"
          : isSignupState
            ? "Get started with your 5-day free trial."
            : "Continue by entering your details below"}
      </p>
      {invitationByCode && (
        <p className="text-lg-sb mt-[14px] text-gray-900">
          Invitation from{" "}
          <span className="text-brand-700">
            {invitationByCode?.data.workspace?.name || "Team"}
          </span>
        </p>
      )}
      <motion.div
        variants={fadeIn}
        initial="initial"
        transition={{
          staggerChildren: 0.1,
        }}
        animate="animate"
        className="flex flex-col mt-[20px] gap-[5px] w-full relative"
      >
        <AnimatePresence mode="wait">
          {isInitialState && (
            <motion.div
              key="google-button"
              variants={fadeIn}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
                exit: { opacity: 0, y: -10 },
              }}
            >
              <SButton
                onClick={handleGoogleLogin}
                size="lg"
                type="secondaryGray"
                lIconProps={{
                  color: "#EA4335",
                }}
                lIcon={faGoogle}
                className="w-full"
              >
                Continue with Google
              </SButton>
              <div className="flex items-center gap-4 w-full mt-[10px]">
                <div className="h-[1px] bg-gray-200 flex-1" />
                <span className="text-sm text-gray-400 uppercase">or</span>
                <div className="h-[1px] bg-gray-200 flex-1" />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="relative">
          <AnimatePresence mode="wait">
            {isSignupState && (
              <motion.div
                key="name-input"
                variants={fadeIn}
                transition={{
                  duration: 0.4,
                  ease: "easeInOut",
                }}
              >
                <SInput
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  label="What's your name?"
                  onKeyDown={(e) => {
                    if (e.code === "Enter") handleSubmit();
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="relative">
          <motion.div
            key="email-input"
            variants={fadeIn}
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
          >
            <SInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              label={!isInitialState && "Email"}
              onKeyDown={(e) => {
                if (e.code === "Enter") handleSubmit();
              }}
            />
          </motion.div>
        </div>

        <div className="relative">
          <AnimatePresence mode="wait">
            {!isInitialState && (
              <motion.div
                key="password-input"
                variants={fadeIn}
                transition={{
                  duration: 0.2,
                  ease: "easeOut",
                }}
              >
                <SInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter your password"
                  label={!isInitialState && "Password"}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") handleSubmit();
                  }}
                  inputProps={{
                    autoFocus: !isSignupState,
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <AnimatePresence mode="wait">
          {error && (
            <motion.pre
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="whitespace-pre-wrap text-error-500 mt-[12px]"
            >
              {error}
            </motion.pre>
          )}
        </AnimatePresence>
      </motion.div>

      <motion.div
        variants={fadeIn}
        className="mt-[20px] flex flex-col gap-[16px] w-full"
      >
        <SButton
          isLoading={isSignInPending || isSignUpPending}
          size="lg"
          className="bg-h-gradient bg-[length:200%_200%] animate-h-gradient transition-all duration-200 hover:brightness-[0.90]"
          onClick={handleSubmit}
        >
          {isInitialState
            ? "Continue"
            : isLoginState
              ? "Sign In"
              : "Create account"}
        </SButton>
      </motion.div>

      {isInitialState && (
        <p className="text-sm mt-[24px] text-center text-gray-600">
          By continuing, you agree to STORI AI's{" "}
          <a
            href={"https://storiai.com/doc/Terms.pdf"}
            target={"_blank"}
            rel="noreferrer"
            className="text-sm-sb text-brand-700 cursor-pointer"
          >
            {" "}
            TOS
          </a>{" "}
          and
          <a
            href={"https://storiai.com/doc/privacy.pdf"}
            target={"_blank"}
            rel="noreferrer"
            className="text-sm-sb text-brand-700 cursor-pointer"
          >
            {" "}
            Privacy Policy
          </a>
        </p>
      )}

      {isSignupState && (
        <p className="text-sm mt-[32px] text-center text-gray-600">
          Already have an account?
          <span
            className="text-sm-sb text-brand-700 cursor-pointer"
            onClick={() => {
              resetForm();
              setFormState(FORM_STATES.INITIAL);
            }}
          >
            {" "}
            Log in
          </span>
        </p>
      )}

      {isLoginState && (
        <p className="text-sm mt-[32px] text-center text-gray-600">
          Forgot your password?
          <span
            className="text-sm-sb text-brand-700 cursor-pointer"
            onClick={() => navigate("/forgot-password")}
          >
            {" "}
            Reset password
          </span>{" "}
          or
          <span
            className="text-sm-sb text-brand-700 cursor-pointer"
            onClick={() => {
              resetForm();
              setFormState(FORM_STATES.INITIAL);
            }}
          >
            {" "}
            Log in
          </span>
        </p>
      )}
    </motion.div>
  );
}

export default Form;
