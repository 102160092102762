import { useEffect, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useUpdatePost } from "../../../hooks/useUpdatePost";
import { SocialNetworkType } from "../../../../../utils/enums/Enums";
import { ISinglePostVM } from "../../../../../utils/interfaces/IPost";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import _ from "lodash";
import { useSocialScore } from "../../../hooks/useSocialScore";
import { useBrandStore } from "../../../../../store/brandStore";
export const useReturnedTextVersions = (
  initialLoad: boolean,
  setIsUpdatedByCopilot: (value: boolean) => void
) => {
  const { updateTextUI } = useUpdatePost();
  const { briefMessages, postData, setPostData, activeSoc, setActiveSoc } =
    useCreationStore();
  const {
    getSocialNetworkPostWithSocialNetworkType,
    getSocialNetworkTypeByName,
  } = useCreationHelperFunctions();
  const { currentBrand } = useBrandStore();
  const { toggleSocialNetworkInPost } = useCreationHelperFunctions();
  const { generateSocialScore } = useSocialScore();

  useEffect(() => {
    const handleTextVersions = async () => {
      const lastMessage = briefMessages[briefMessages.length - 1];
      initialLoad && setIsUpdatedByCopilot(true);
      let socialNetworkTypesToUpdate: {
        type: SocialNetworkType;
        text: string;
      }[] = [];
      if (
        initialLoad &&
        lastMessage?.content &&
        lastMessage.content.Type === "TextVersions"
      ) {
        // daabruna xutive soc kselistvis
        // davsete tmpPostDatashi kvela text versia kvela soc kselistvis da bolos gavushvi backshi. jer state davaaupdate

        if (
          lastMessage?.content &&
          lastMessage.content.Type === "TextVersions"
        ) {
          lastMessage.content.Data.TextVersions!.forEach((item) => {
            const socialNetworkType = getSocialNetworkTypeByName(
              item.SocialNetworkType
            );
            if (socialNetworkType) {
              const isActiveInBrand =
                currentBrand?.activeSocialNetworks.includes(socialNetworkType);
              const isActiveInPost =
                postData?.socialNetworks.includes(socialNetworkType);
              if (isActiveInBrand && !isActiveInPost) {
                socialNetworkTypesToUpdate.push({
                  type: socialNetworkType,
                  text: item.TextVersion,
                });
              }
            }
          });
        }
        let tmpPostData: ISinglePostVM | undefined = undefined;

        if (socialNetworkTypesToUpdate.length > 0) {
          tmpPostData = await toggleSocialNetworkInPost({
            append: true,
            socialNetworkTypes: socialNetworkTypesToUpdate,
          });
        } else {
          tmpPostData = _.cloneDeep(postData);
        }
        if (lastMessage.content.Data.TextVersions!.length > 1) {
          lastMessage.content.Data.TextVersions!.map((item) => {
            tmpPostData?.socialNetworkPosts.map((socItem) => {
              if (
                getSocialNetworkTypeByName(item.SocialNetworkType) ===
                socItem.socialNetworkType
              ) {
                socItem.text = item.TextVersion;
              }
            });
          });
          setPostData(tmpPostData as ISinglePostVM);
          // putPost({
          //   postData: tmpPostData as ISinglePostVM,
          //   postId: postData!.id,
          // }).then((res) => {
          //   generateSocialScore();
          // });
        }
        // daabruna erti soc kselistvis
        else {
          if (
            initialLoad &&
            lastMessage.content.Data.TextVersions?.length === 1
          ) {
            const socialNetworkType = getSocialNetworkTypeByName(
              lastMessage.content.Data.TextVersions[0].SocialNetworkType
            ) as SocialNetworkType;
            if (activeSoc !== socialNetworkType)
              setActiveSoc(socialNetworkType);
            updateTextUI({
              text: lastMessage.content.Data.TextVersions[0].TextVersion,
              socialNetworkPostId:
                getSocialNetworkPostWithSocialNetworkType(socialNetworkType)
                  ?.id,
            });
          }
          let forWhichSocialNetworkTypeToGenerateScore: number | null = null;
          lastMessage.content.Data.TextVersions!.map((item) => {
            tmpPostData?.socialNetworkPosts.map((socItem) => {
              if (
                getSocialNetworkTypeByName(item.SocialNetworkType) ===
                socItem.socialNetworkType
              ) {
                socItem.text = item.TextVersion;
                forWhichSocialNetworkTypeToGenerateScore =
                  socItem.socialNetworkType;
              }
            });
          });
          setPostData(tmpPostData as ISinglePostVM);
          generateSocialScore(
            tmpPostData?.socialNetworkPosts.find(
              (soc) =>
                soc.socialNetworkType ===
                forWhichSocialNetworkTypeToGenerateScore
            )?.id
          );
        }
      }
    };
    handleTextVersions();
  }, [briefMessages]);
};
