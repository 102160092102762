import {
  faSparkles,
  faGif,
  faImage,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SButton from "../../../../design-system/SButton";
import { LibraryDropdown } from "../../../Library/LibraryDropdown";

export interface IShortcutButtonsProps {
  setActiveTab: (
    value: "generator" | "stocks" | "search" | "selector" | "gifs"
  ) => void;
  setIsOpen: (value: boolean) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  dropboxRef: React.RefObject<HTMLInputElement>;
  selectFile: (options: { multiple: boolean }) => void;
}

export const ShortcutButtons = ({
  setActiveTab,
  setIsOpen,
  setOpenModal,
  dropboxRef,
  selectFile,
}: IShortcutButtonsProps) => {
  return (
    <div className="w-full px-4 md2:px-0 whitespace-nowrap grid grid-cols-2 md:grid-cols-5 gap-4 items-center">
      <SButton
        onClick={() => {
          setActiveTab("generator");
          setIsOpen(true);
        }}
        size="xl"
        className="text-lg w-full"
        type="secondaryGray"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faSparkles} className="text-[18px]" />
          <p>
            Generate <span className="hidden xl:inline">Image</span>
          </p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setActiveTab("gifs");
          setIsOpen(true);
        }}
        size="xl"
        className="text-lg w-full"
        type="secondaryGray"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faGif} className="text-[18px]" />
          <p>
            GIFs <span className="hidden xl:inline">& Clips</span>
          </p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setActiveTab("stocks");
          setIsOpen(true);
        }}
        size="xl"
        className="text-lg w-full"
        type="secondaryGray"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faImage} className="text-[18px]" />
          <p>
            <span className="hidden xl:inline">Stock </span>Photos
          </p>
        </div>
      </SButton>
      <SButton
        onClick={() => {
          setActiveTab("search");
          setIsOpen(true);
        }}
        size="xl"
        className="text-lg w-full"
        type="secondaryGray"
      >
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon icon={faSearch} className="text-[18px]" />
          <p>
            Search <span className="hidden xl:inline">Assets</span>
          </p>
        </div>
      </SButton>
      <LibraryDropdown
        btnClassName="w-full md:w-fit col-span-2 md:col-span-1 relative ml-auto z-[10]"
        handleFiles={() => selectFile({ multiple: true })}
        handleFolder={() => setOpenModal(true)}
        handleDropbox={() => dropboxRef.current?.click()}
        isContent={true}
      />
    </div>
  );
};
