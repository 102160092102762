import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBrandCreationData, Step } from "../../pages/CreateBrand";
import { faCircle1, faCircle2 } from "@fortawesome/pro-regular-svg-icons";

function Stepper({
  steps,
  currentStep,
  setCurrentStep,
}: {
  handleCreatBrand: () => void;
  isBrandCreating: boolean;
  ready: boolean;
  data: IBrandCreationData;
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
}) {
  return (
    <div className="flex justify-center items-center gap-[16px]">
      <div className="flex items-center">
        {steps.map((item, index) => (
          <div
            key={index}
            className={`${index === 1 && currentStep !== 1 && "opacity-50"} flex items-center`}
          >
            <div
              onClick={() => {
                if (currentStep !== 1 && index !== 1 && currentStep !== index)
                  setCurrentStep(index);
              }}
              style={{
                outline: "1px solid transparent",
                outlineOffset: "4px",
                zIndex: currentStep === index ? 10 : 1,
              }}
              className={`
                cursor-pointer max-h-[40px] flex items-center gap-[8px] px-[12px] py-[10px] bg-base-white border border-gray-200 rounded-[8px]
                ${currentStep === index ? "!outline-brand-200 !border-brand-600" : ""}
              `}
            >
              <FontAwesomeIcon
                className={`text-[20px] ${currentStep === index ? "text-brand-600" : "text-gray-400"}`}
                icon={index === 0 ? faCircle1 : faCircle2}
              />
              <p
                className={`${currentStep === index ? "text-sm-sb text-gray-700" : "text-sm text-gray-600"} max-md:hidden`}
              >
                {item.name}
              </p>
            </div>
            {index < steps.length - 1 && (
              <div className="flex-grow h-[2px] relative z-0 w-[34px]">
                {currentStep === 0 && index === 0 && (
                  <div className="absolute left-0 h-full w-[4px] bg-white z-10"></div>
                )}
                {currentStep === 1 && index === 0 && (
                  <div className="absolute right-0 h-full w-[4px] bg-white z-10"></div>
                )}
                <div className="flex-grow h-full bg-gray-200"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Stepper;
