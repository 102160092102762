import React, { useEffect } from "react";
import useNotificationSocketStore from "../../store/notificationsStore";
import useNotificationToast from "./useNotificationToast";

interface INotificationProvider {
  children: React.ReactNode;
}

function NotificationProvider({ children }: INotificationProvider) {
  const { liveMessages, removeLiveMessage } = useNotificationSocketStore();
  const { addToast, removeToast, ToastContainer, generateId } =
    useNotificationToast();
  useEffect(() => {
    if (liveMessages.length > 0) {
      const lastMessage = liveMessages[liveMessages.length - 1];
      addToast({
        title: "new not",
        duration: 3,
      });
      removeLiveMessage(lastMessage.id);
    }
  }, [liveMessages]);
  return (
    <>
      {/* <ToastContainer /> */}
      {children}
    </>
  );
}

export default NotificationProvider;
