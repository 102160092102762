import React, { useRef, useState } from "react";
import {
  faChevronDown,
  faSearch,
  faSparkles,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../../design-system/SButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../../../../store/creationStore";
import { useHelperInputHelperFunctions } from "./useHelperInputHelperFunctions";
import { useCreationLibraryHelperFunctions } from "../hooks/useCreationLibraryHelperFunctions";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../utils/enums/Enums";
import { toast } from "../../../../../hooks/toast";
import { useGetWorkspaceUsage } from "../../../../../api/workspaces/limits/get";
import SSmallMenu from "../../../../../design-system/SSmallMenu";
import SRadio from "../../../../../design-system/SRadio";

//@ts-ignore
import gifsIcon from "../../../../../assets/emojies/fire.svg";
//@ts-ignore
import stocksIcon from "../../../../../assets/emojies/framed-picture.svg";
//@ts-ignore
import searchIcon from "../../../../../assets/emojies/magnifying-glass.svg";
//@ts-ignore
import generatorIcon from "../../../../../assets/emojies/magic-wand.svg";
import { useLibraryStore } from "../../../../../store/libraryStore";
import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";

export type Tab = "generator" | "stocks" | "search" | "selector" | "gifs";

function Input({
  isOpen,
  setIsOpen,
  activeTab,
  setActiveTab,
  setInputValue,
  inputValue,
  activeStyleTag,
  activeAspectRatio,
  setGifSearch,
  setType,
  type,
  setSelected,
}: {
  activeStyleTag: ImageGenerationStyle;
  activeAspectRatio: ImageGenerationAspectRatio;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setGifSearch: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<"Clips" | "Gifs">>;
  type: "Gifs" | "Clips";
  activeTab: Tab;
  setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
}) {
  const { handleGenerateImage } = useCreationLibraryHelperFunctions();
  const { setLibrarySearchValue } = useCreationStore();
  const { handleSearchStocks } = useHelperInputHelperFunctions();
  const inputRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  const [isSmallMenuOpen, setIsSmallMenuOpen] = useState(false);
  const { setFoldersAndFiles, setPage } = useLibraryStore();
  const items: Record<Tab, { text: string; image: JSX.Element }> = {
    generator: {
      text: "Image Creator",
      image: (
        <img src={generatorIcon} alt="generatorIcon" className="w-[16px]" />
      ),
    },
    stocks: {
      text: "Stock Photos",
      image: <img src={stocksIcon} alt="stocksIcon" className="w-[16px]" />,
    },
    search: {
      text: "Search",
      image: <img src={searchIcon} alt="searchIcon" className="w-[16px]" />,
    },
    gifs: {
      text: "GIFs & Clips",
      image: (
        <img src={gifsIcon} alt="gifsicon" className="w-[16px] scale-[0.85]" />
      ),
    },
    selector: {
      text: "Generator",
      image: (
        <img src={generatorIcon} alt="selectorIcon" className="w-[16px]" />
      ),
    },
  };
  const handleSmallMenuClick = (
    tab: Tab,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    setActiveTab(tab);
    setIsSmallMenuOpen(false);
  };
  return (
    <div
      className={`${isOpen && "mx-[24px]"} transition-all duration-500 ease-in-out`}
      onClick={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
    >
      {isOpen && (
        <div
          className={`flex w-full border items-center border-gray-200 bg-gray-100 pl-[2px] rounded-[8px]`}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsSmallMenuOpen((value) => !value);
            }}
            id="creation-art-dropdown"
            className="min-w-[180px] px-4 cursor-pointer z-[61] relative max-md:hidden max-md:rounded-lg bg-base-white rounded-l-lg flex justify-between items-center gap-2 shadow-sm p-[8px]"
          >
            <div className="flex items-center gap-2">
              {items[activeTab].image}
              <p className="text-sm whitespace-nowrap">
                {items[activeTab].text}
              </p>
            </div>
            <FontAwesomeIcon icon={faChevronDown} />
            <SSmallMenu
              excludedId="creation-art-dropdown"
              isOpen={isSmallMenuOpen}
              className="text-sm w-full z-[61] whitespace-nowrap"
              setIsOpen={setIsSmallMenuOpen}
              data={[
                [
                  {
                    text: items["generator"].text,
                    handleClick: (e) => handleSmallMenuClick("generator", e),
                    additionalItem: items["generator"].image,
                    rAdditionalItem: (
                      <SRadio size="sm" checked={activeTab === "generator"} />
                    ),
                  },
                  {
                    text: items["gifs"].text,
                    handleClick: (e) => handleSmallMenuClick("gifs", e),
                    additionalItem: items["gifs"].image,
                    rAdditionalItem: (
                      <SRadio size="sm" checked={activeTab === "gifs"} />
                    ),
                  },
                  {
                    text: items["stocks"].text,
                    handleClick: (e) => handleSmallMenuClick("stocks", e),
                    additionalItem: items["stocks"].image,
                    rAdditionalItem: (
                      <SRadio size="sm" checked={activeTab === "stocks"} />
                    ),
                  },
                  {
                    text: items["search"].text,
                    handleClick: (e) => handleSmallMenuClick("search", e),
                    additionalItem: items["search"].image,
                    rAdditionalItem: (
                      <SRadio size="sm" checked={activeTab === "search"} />
                    ),
                  },
                ],
              ]}
            />
          </div>
          <input
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (activeTab === "search") {
                  inputRef.current.blur();
                  setLibrarySearchValue(inputValue);
                  setIsOpen(false);
                }
                if (activeTab === "stocks") {
                  handleSearchStocks(inputValue);
                }
                if (activeTab === "gifs") {
                  setGifSearch(inputValue);
                  return;
                }
                if (
                  !isLoading &&
                  (activeTab === "generator" || activeTab === "selector")
                ) {
                  if (inputValue.length > 0) {
                    setIsLoading(true);
                    handleGenerateImage({
                      prompt: inputValue,
                      style: activeStyleTag,
                      aspectRatio: activeAspectRatio,
                    })
                      .then((res) => {
                        setActiveTab("selector");
                        setSelected(res.data);
                      })
                      .finally(() => setIsLoading(false));
                  }
                }
              }
            }}
            value={inputValue}
            onChange={(e) => {
              setGifSearch(e.target.value);
              setInputValue(e.target.value);
            }}
            className="px-[16px] py-[10px] text-sm border-0 w-full outline-none focus:outline-0 focus:ring-0 text-md placeholder:text-gray-500 text-gray-900 bg-transparent"
            placeholder={
              activeTab === "generator" || activeTab === "selector"
                ? "Enter your prompt here"
                : activeTab === "search"
                  ? "Search in your assets"
                  : activeTab === "gifs"
                    ? "Search in KLIPY.co"
                    : "Search images"
            }
          />
          {isOpen && (
            <>
              {(activeTab === "generator" || activeTab === "selector") && (
                <SButton
                  isLoading={isLoading}
                  // lIconProps={{

                  // }}
                  onClick={() => {
                    if (!workspaceUsage?.data.canUserGenerateImages) {
                      toast(
                        "You have reached the limit of generations for your workspace.",
                        "error"
                      );
                      return;
                    }
                    if (inputValue.length > 0 && !isLoading) {
                      setIsLoading(true);
                      handleGenerateImage({
                        prompt: inputValue,
                        style: activeStyleTag,
                        aspectRatio: activeAspectRatio,
                      })
                        .then((res) => {
                          toast("Image generated", "success");
                          setSelected(res.data);
                          setActiveTab("selector");
                        })
                        .catch((err) => {
                          toast(err.response.data.message, "error");
                        })
                        .finally(() => setIsLoading(false));
                    }
                  }}
                  size="sm"
                  className="mr-[4px]"
                  lIcon={faSparkles}
                >
                  Generate
                </SButton>
              )}
              {activeTab === "search" && (
                <div className="flex items-center gap-[12px]">
                  <FontAwesomeIcon
                    onClick={() => {
                      setInputValue("");
                      setLibrarySearchValue("");
                      setIsOpen(false);
                      setFoldersAndFiles([]);
                      setPage(1);
                    }}
                    icon={faX}
                    className="text-[10px] cursor-pointer text-gray-600"
                  />
                  <SButton
                    onClick={() => {
                      setLibrarySearchValue(inputValue);
                      setIsOpen(false);
                    }}
                    isLoading={isLoading}
                    size="sm"
                    type="secondaryGray"
                    className="mr-[4px]"
                    lIcon={faSearch}
                  >
                    Search
                  </SButton>
                </div>
              )}
              {activeTab === "gifs" && (
                <div className="flex items-center gap-[12px] z-[100]">
                  <div
                    id="gif-clip-switcher"
                    className="mr-[6px] flex relative items-center gap-1 text-xs"
                  >
                    <div
                      className="flex items-center gap-1 bg-base-white rounded-[6px] p-[6px] border border-gray-200 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setType("Gifs");
                      }}
                    >
                      <SRadio size="sm" checked={type === "Gifs"} />
                      <p>GIFs</p>
                    </div>
                    <div
                      className="flex items-center gap-1 bg-base-white rounded-[6px] p-[6px] border border-gray-200 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setType("Clips");
                      }}
                    >
                      <SRadio size="sm" checked={type === "Clips"} />
                      <p>Clips</p>
                    </div>
                  </div>
                  {/* <SButton
                    onClick={() => {
                      setGifSearch(inputValue);
                    }}
                    isLoading={isLoading}
                    size="sm"
                    type="secondaryGray"
                    className="mr-[4px]"
                    lIcon={faSearch}
                  >
                    Search
                  </SButton> */}
                </div>
              )}
              {activeTab === "stocks" && (
                <SButton
                  onClick={() => {
                    handleSearchStocks(inputValue);
                  }}
                  size="sm"
                  isLoading={isLoading}
                  type="secondaryGray"
                  className="mr-[4px]"
                  lIcon={faSearch}
                >
                  Search
                </SButton>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Input;
