import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuery } from "../hooks/functions/queries";
import { usePostCanvaLInk } from "../api/auth/canva/link/post";

const CanvaRedirect = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const { mutateAsync: linkCanva } = usePostCanvaLInk();
  const location = useLocation();
  const isFromCanva = getQuery("isFromCanva");
  useEffect(() => {
    if (isAuthenticated) {
      if (!isFromCanva) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      const canvaToken = getQuery("canva_user_token");
      linkCanva({ canvaToken: canvaToken! }).then((res) => {
        const state = getQuery("state");
        window.location.replace(
          `https://www.canva.com/apps/configured?success=${true}&state=${state}`
        );
      });
    } else {
      const searchParams = new URLSearchParams(location.search);
      navigate(
        `/canva-login${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
      );
    }
  }, [isAuthenticated]);

  return <></>;
};

export default CanvaRedirect;
