import { useEffect, useState } from "react";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import NotificationsTabInHeader from "./NotificationsTabInHeader";
import { useValueByType } from "../notifications/hooks/useValueByType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetNotifications } from "../../api/notifications/get";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import useNotificationSocketStore from "../../store/notificationsStore";

const NotificationsInHeader = ({ ...props }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [headerNotifications, setHeaderNotifications] = useState<
    ISingleNotification[]
  >([]);
  const { data, isLoading, isFetching } = useGetNotifications({
    page: 1,
    pageSize: 10,
  });
  const { getValue } = useValueByType();
  const {
    liveMessages,
    removeLiveMessage,
    initNotificationSignalR,
    receiveLiveMessage,
  } = useNotificationSocketStore();

  useEffect(() => {
    initNotificationSignalR();
    receiveLiveMessage();
  }, []);

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      setHeaderNotifications([...data.data.notifications]);
    }
  }, [data, isFetching, isLoading]);

  useEffect(() => {
    if (liveMessages.length > 0) {
      const lastMessage = liveMessages[liveMessages.length - 1];
      setHeaderNotifications([lastMessage, ...headerNotifications]);
      removeLiveMessage(lastMessage.id);
    }
  }, [liveMessages]);

  return (
    <>
      <div className={"relative"}>
        <div
          className={
            "w-[36px] h-[36px] flex items-center justify-around border border-gray-200 rounded-[8px] hover:bg-gray-200 hover:border-gray-300 transition-all cursor-pointer"
          }
          id={"header__notifications-opener"}
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
        >
          {headerNotifications.find((item) => !item.isRead) && (
            <div
              className={
                "absolute bottom-5 left-5 w-5 h-5 flex justify-center items-center bg-orange-500 rounded-full"
              }
            >
              <p className="text-xs-b text-base-white">
                {
                  headerNotifications.filter((item, index) => !item.isRead)
                    .length
                }
              </p>
            </div>
          )}
          <FontAwesomeIcon
            icon={faBell}
            className={"text-[16px] text-gray-700"}
          />
        </div>
      </div>
      {isNotificationsOpen && (
        <NotificationsTabInHeader
          isFetching={isFetching}
          isNotificationsOpen={isNotificationsOpen}
          setIsNotificationsOpen={setIsNotificationsOpen}
          notifications={headerNotifications}
          getValue={getValue}
          setHeaderNotifications={setHeaderNotifications}
        />
      )}
    </>
  );
};

export default NotificationsInHeader;
