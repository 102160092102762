import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateGAClientId } from "../googleAnalytics/put";
import { IBase, IBaseData } from "../../../utils/interfaces/IBase";
import { ITokenVM } from "../../../utils/interfaces/IToken";
import { ISignInCOE } from "../../../utils/interfaces/IAuth";
import { makeRequest } from "../axios";
import { ILoginResponse } from "../../../utils/interfaces/ILoginResponse";

export const useSignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { mutateAsync: updateClientId } = useUpdateGAClientId();
  const getGaClientId = (): string => {
    const cookie: { [key: string]: string } = {};
    document.cookie.split(";").forEach((el) => {
      const [key, value] = el.split("=").map((part) => part.trim());
      cookie[key] = value;
    });
    return cookie["_ga"]?.substring(6) || "";
  };

  return useMutation<IBase<ILoginResponse>, any, ISignInCOE>({
    mutationFn: ({ email, password }) =>
      makeRequest.post("/Auth/login", {
        emailAddress: email,
        password: password,
      }),
    onSuccess: ({ data }) => {
      localStorage.setItem("token", data.data.authToken.accessToken);
      navigate(`/${location?.search}`);
      const clientId = getGaClientId();
      updateClientId({ clientId: clientId });
    },
  });
};
