import { faMicrophone, faStop } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import SButton from "../../../design-system/SButton";
import VoiceRecorder from "../../creation/Tabs/Brief/VoiceRecorder";
import { faPaperPlaneAlt } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";

function Textarea({
  value,
  setValue,
  sendMessage,
  disable,
  tooltipMessage,
}: {
  disable: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
  tooltipMessage?: string;
}) {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast(
        "Your browser does not support speech recognition. Please try a different browser."
      );
    }
  }, []);
  const [lastState, setLastState] = useState("");
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  return (
    <div className="relative mt-[32px] max-h-[84px] h-full">
      <textarea
        id="create-brand-chat-textarea"
        disabled={disable}
        placeholder="Send a message..."
        className="px-[18px] py-[16px] h-full focus-visible:outline-[0] focus:border-gray-200 pr-[100px] focus:ring-0 w-full text-md bg-gray-25 border border-gray-100 rounded-b-[10px] resize-none"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
          // handleChange(e);
        }}
        value={listening ? lastState + " " + value : value}
        onKeyDown={(e) => {
          if (e.shiftKey && e.key === "Enter") {
            return null;
          } else if (e.code == "Enter" && value && value.length > 0) {
            e.preventDefault();
            sendMessage(value);
          }
        }}
      />
      <div className="absolute bottom-[12px] right-[12px] flex gap-[6px]">
        <VoiceRecorder
          disabled={disable}
          value={value}
          setValue={setValue}
          setLastState={setLastState}
          lastState={lastState}
          listening={listening}
          transcript={transcript}
          resetTranscript={resetTranscript}
        />
        <Tooltip id="send-message-tooltip">{tooltipMessage}</Tooltip>
        <SButton
          data-tooltip-id="send-message-tooltip"
          type={"secondaryColor"}
          disabled={disable}
          size="md"
          style={{
            border: "none",
            background: "none",
          }}
          onClick={() => {
            if (value.length > 0) {
              sendMessage(value);
            }
          }}
        >
          <FontAwesomeIcon icon={faPaperPlaneAlt} />
        </SButton>
      </div>
    </div>
  );
}

export default Textarea;
