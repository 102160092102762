import React, { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import { LongPressEventType, useLongPress } from "use-long-press";
import { toast } from "../../../../hooks/toast";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { useGetWindowWidth } from "../../../../hooks/useGetWindowWidth";

interface VoiceRecorderProps {
  className?: string;
  canvasProps?: string;
  value: string;
  setValue: (value: string) => void;
  lastState: string;
  setLastState: (lastState: string) => void;
  listening: boolean;
  transcript: string;
  resetTranscript: () => void;
  additionalFunction?: () => void;
  inputRef?: React.RefObject<any>;
  disabled?: boolean;
}

const VoiceRecorder = ({
  className,
  disabled,
  canvasProps,
  value,
  setValue,
  lastState,
  setLastState,
  listening,
  transcript,
  resetTranscript,
  inputRef,
  additionalFunction,
}: VoiceRecorderProps) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const animationRef = useRef<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  const { windowWidth } = useGetWindowWidth();
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript, setValue, listening]);

  useEffect(() => {
    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const handleMouseDown = async () => {
    if (listening || isPostPublishedOrScheduled()) return; // Prevents action if already listening

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast(
        "Your browser does not support speech recognition. Please try a different browser."
      );
      return;
    }
    // Remove extra spaces from start and end of value
    resetTranscript();
    setLastState(value);
    // setValue("");
    await SpeechRecognition.startListening({ continuous: true });
    await startVisualizer();
  };

  const handleMouseUp = async () => {
    if (!listening) return; // Prevents action if not listening

    if (lastState.length > 0) setValue(lastState.trim() + " " + value.trim());
    setLastState("");
    await SpeechRecognition.stopListening();
    stopVisualizer();
    resetTranscript();
    additionalFunction && additionalFunction();
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };
  const startVisualizer = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      animateVisualizer();
    } catch (error) {
      toast("Error accessing microphone. Please check your permissions.");
    }
  };

  const stopVisualizer = () => {
    if (animationRef.current !== null) {
      cancelAnimationFrame(animationRef.current);
    }
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  const animateVisualizer = () => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationRef.current = requestAnimationFrame(draw);
      if (!analyserRef.current) return;

      analyserRef.current.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = "rgb(255, 255, 255)";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      const barWidth = 4;
      const barGap = 2;
      const barCount = Math.floor(canvas.width / (barWidth + barGap));
      const dataStep = Math.floor(bufferLength / barCount);
      const centerY = canvas.height / 2;

      for (let i = 0; i < barCount; i++) {
        const dataIndex = i * dataStep;
        const barHeight = (dataArray[dataIndex] / 255) * canvas.height;
        const x = i * (barWidth + barGap);
        const y = centerY - barHeight / 2;

        canvasCtx.fillStyle = "rgb(143,88,233)";
        canvasCtx.beginPath();
        canvasCtx.moveTo(x, y + barHeight / 2);
        canvasCtx.arcTo(x, y, x + barWidth, y, barWidth / 2);
        canvasCtx.arcTo(
          x + barWidth,
          y,
          x + barWidth,
          y + barHeight,
          barWidth / 2
        );
        canvasCtx.arcTo(
          x + barWidth,
          y + barHeight,
          x,
          y + barHeight,
          barWidth / 2
        );
        canvasCtx.arcTo(x, y + barHeight, x, y, barWidth / 2);
        canvasCtx.fill();
      }
    };

    draw();
  };

  const bind = useLongPress(
    () => {
      if (windowWidth > 1000) return;
      handleMouseDown();
    },
    {
      onStart: (event, meta) => {
        // console.log("Press started", meta);
      },
      onFinish: (event, meta) => {
        if (windowWidth > 1000) return;
        handleMouseUp();
      },
      onCancel: (event, meta) => {
        if (isPostPublishedOrScheduled() || windowWidth > 1000) return;
        toast("Please click and hold the microphone button to record", "info");
      },
      filterEvents: (event) => true,
      threshold: 1000,
      captureEvent: true,
      cancelOnMovement: false,
      cancelOutsideElement: true,
      detect: LongPressEventType.Pointer,
    }
  );

  const preventSelection = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
  };

  const handlers = bind("voice recorder context");
  return (
    <div className="relative flex flex-col items-center">
      {listening && (
        <div
          className={`absolute bottom-14 w-[100px] h-[40px] mb-2 ${canvasProps}`}
        >
          <canvas
            ref={canvasRef}
            width="100"
            height="50"
            className="rounded-md border border-gray-300"
          />
          <div
            className="absolute -top-2 -right-2 w-6 h-6 flex justify-center items-center bg-gray-200 rounded-full cursor-pointer"
            onClick={handleMouseUp}
          >
            <FontAwesomeIcon icon={faX} className="text-gray-700 text-xs" />
          </div>
        </div>
      )}
      <div
        {...handlers}
        onClick={() => {
          if (windowWidth <= 1000 || disabled) return;
          if (listening) {
            handleMouseUp();
          } else {
            handleMouseDown();
          }
        }}
        onMouseDown={preventSelection}
        onTouchStart={preventSelection}
        className={`${className}
          ${listening && windowWidth <= 1000 ? "bg-red-500 text-white" : "text-gray-500"}
          ${listening && windowWidth > 1000 ? "bg-success-500 text-white" : ""}
          w-10 h-10 rounded-full flex justify-center items-center ${isPostPublishedOrScheduled() || disabled ? "cursor-default" : "cursor-pointer"} transition-colors duration-300
          select-none touch-none
        `}
      >
        {windowWidth <= 1000 ? (
          <FontAwesomeIcon icon={listening ? faCircle : faMicrophone} />
        ) : (
          <FontAwesomeIcon icon={listening ? faCheck : faMicrophone} />
        )}
      </div>
    </div>
  );
};

export default VoiceRecorder;
