import { useEffect, useState } from "react";

import PreviewSide from "../components/creation/previews/PreviewSide";
import SButton from "../design-system/SButton";
import {
  faCloudArrowUp,
  faCopy,
  faRefresh,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import CreationHeader from "../components/creation/CreationHeader";
import Brief from "../components/creation/Tabs/Brief";
import { useInitializePost } from "../components/creation/hooks/useInitializePost";
import { useCreationStore } from "../store/creationStore";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import Channels from "../components/creation/Tabs/Channels";
import Media from "../components/creation/Tabs/Media";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";
import {
  OrganizationUserRole,
  PostStatus,
  TeamPackageStatus,
} from "../utils/enums/Enums";
import VideoSelectionAgreement from "../components/creation/modals/VideoSelectionAgreement";
import Frames from "../components/creation/Tabs/Frames";
import { useAccountStore } from "../store/accountStore";
import SpeechRecognition from "react-speech-recognition";
import { useGetWindowWidth } from "../hooks/useGetWindowWidth";
import MobileSectionsDrawer from "../components/creation/MobileSectionsDrawer";
import { Tooltip } from "react-tooltip";
import { toast } from "../hooks/toast";
import { useSocialScore } from "../components/creation/hooks/useSocialScore";
import { useUpdatePost } from "../components/creation/hooks/useUpdatePost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SessionErrored from "../components/creation/Tabs/Brief/SessionErrored";
import SOptionButon from "../design-system/SOptionButton";
import { usePublishing } from "../components/creation/hooks/usePublishing";
import ScheduleModal from "../components/creation/modals/ScheduleModal";
import { BillingInfo } from "../components/billing/BillingInfo";
import { useCreationSockets } from "../components/creation/hooks/useCreationSockets";
import { useSignalR } from "../socket-services/useSignalR";
import { useGetCurrentPackage } from "../api/subscriptions/current/get";
import { useLibraryStore } from "../store/libraryStore";

const Creation = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { connected, subscribeToEvent, sendEvent, disconnect } = useSignalR(
    `${process.env.REACT_APP_API_ENDPOINT}hubs/posts`
  );
  useCreationSockets({
    connected,
    subscribeToEvent,
    sendEvent,
  });
  useInitializePost();
  const { data: currentPlan } = useGetCurrentPackage();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [showShareButton, setShowShareButton] = useState(true);
  const {
    activeTab,
    setActiveTab,
    activeSoc,
    socialScore,
    sessionErrored,
    inAdvanceDate,
    postUpdating,
    tabsOverlay,
    setSocialScore,
    postData,
    isSocialScoreLoading,
    isPublishing,
    selectedSocialNetworks,
    setSelectedSocialNetworks,
    setIsMobileSectionsDrawerOpen,
    setLibrarySearchValue,
    positions,
    setPositions,
  } = useCreationStore();
  const { handlePublishNow } = usePublishing();
  useLockBodyScroll(isOpen);
  const {
    isPostPublishedOrScheduled,
    getActiveSocialNetworkPost,
    handleDeleteEmptyPost,
  } = useCreationHelperFunctions();
  const { updateSocialScoreIntoPost } = useUpdatePost();
  useEffect(() => {
    if (isPostPublishedOrScheduled()) {
      setActiveTab("accounts");
    }
  }, [postData, activeSoc]);
  const { setFoldersAndFiles, setPage } = useLibraryStore();
  useEffect(() => {
    setFoldersAndFiles([]);
    setPage(1);
    setLibrarySearchValue("");
  }, [activeTab]);
  useEffect(() => {
    if (activeTab === "accounts") {
      setIsMobileSectionsDrawerOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!postData) return;
    if (selectedSocialNetworks.length === 0) {
      setShowShareButton(true);
      return;
    }
    const unpublishedNetworks = selectedSocialNetworks.filter((item) => {
      const postWithStatus = postData.socialNetworkPosts.find(
        (sPost) => sPost.socialNetworkType === item.socialNetworkType
      );
      return !(
        postWithStatus?.postStatus === PostStatus.SCHEDULED ||
        postWithStatus?.postStatus === PostStatus.PUBLISHED
      );
    });
    setShowShareButton(unpublishedNetworks.length === 0);
  }, [postData, selectedSocialNetworks]);

  const { personalInfo } = useAccountStore();
  const [isUpdatedByCopilot, setIsUpdatedByCopilot] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { windowWidth } = useGetWindowWidth();

  const handleClose = async () => {
    await disconnect();
    await handleDeleteEmptyPost();
    SpeechRecognition.stopListening();
    onClose();
  };
  const { appendSocialScore } = useSocialScore();

  useEffect(() => {
    if (getActiveSocialNetworkPost() && !socialScore && !isSocialScoreLoading) {
      if (getActiveSocialNetworkPost()!.socialScore) {
        setSocialScore(getActiveSocialNetworkPost()!.socialScore);
      }
    }
    const post = getActiveSocialNetworkPost();
    if (post) {
      setPositions(post.files.sort((a, b) => a.orderId - b.orderId));
    }
  }, [getActiveSocialNetworkPost()]);

  useEffect(() => {
    if (useCreationStore.getState().socialScore && !isSocialScoreLoading) {
      if (
        useCreationStore.getState().socialScore?.socialNetworkType !==
        useCreationStore.getState().activeSoc
      ) {
        appendSocialScore();
      }
    }
  }, [activeSoc]);

  useEffect(() => {
    if (socialScore) {
      updateSocialScoreIntoPost();
    }
  }, [socialScore]);

  const [showPostUpdating, setShowPostUpdating] = useState(false);

  useEffect(() => {
    if (!showPostUpdating && postUpdating) {
      setShowPostUpdating(true);
    }
  }, [postUpdating]);

  useEffect(() => {
    if (showPostUpdating) {
      setTimeout(() => {
        setShowPostUpdating(false);
      }, 1000);
    }
  }, [showPostUpdating]);
  return (
    <>
      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      />
      <VideoSelectionAgreement />
      <Drawer
        open={isOpen}
        onClose={async () => {
          await handleClose();
        }}
        direction="bottom"
        className="min-h-[97dvh] rounded-[24px] max-md:rounded-b-[0px]"
        lockBackgroundScroll={true}
      >
        <div className="flex gap-[58px] absolute top-[20px] right-[15px] pr-2 z-[1000] bg-opacity-25 bg-gray-100 backdrop-filter backdrop-blur-[4px] pl-2 rounded-[12px] py-2  max-md:w-[95%] max-md:top-[80px] max-md:right-[unset] max-md:left-[10px] max-md:!z-[10]">
          <div className="flex gap-[8px] max-md:w-full">
            <div className="flex gap-[8px] max-md:flex-row-reverse">
              <Tooltip id="post-last-state-saved">All changes saved</Tooltip>
              <div
                data-tooltip-id="post-last-state-saved"
                className="flex items-center gap-[4px] mr-[6px] cursor-default"
              >
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className="text-[12px] text-gray-700"
                />
                <p className="text-xs text-gray-600">
                  {showPostUpdating ? "Saving..." : "Saved"}
                </p>
              </div>
              <Tooltip id="copy-post-link">Share Link</Tooltip>
              <SButton
                data-tooltip-id="copy-post-link"
                icon={faCopy}
                onClick={() => {
                  // http://localhost:3000/?redirectType=post&teamId=60&organizationId=1684&postId=10589
                  navigator.clipboard.writeText(
                    `${window.location.origin}/?redirectType=post&workspaceId=${postData?.workspaceId}&organizationId=${postData?.organizationId}&postId=${postData?.id}`
                  );
                  toast("Copied to clipboard", "success");
                }}
                size="md"
                type="secondaryGray"
              />
            </div>
            <div className="flex items-end gap-[6px] md:mr-2 max-md:ml-auto">
              {showShareButton ? (
                <SButton
                  type="secondaryColor"
                  onClick={() => {
                    activeTab !== "accounts" && setActiveTab("accounts");
                    toast(
                      "Please select at least one social network first",
                      "info"
                    );
                    setIsMobileSectionsDrawerOpen(true);
                  }}
                >
                  Share
                </SButton>
              ) : (
                <SOptionButon
                  isLoading={!!isPublishing}
                  type="secondaryColor"
                  disabled={!!isPublishing}
                  className="min-w-[120px]"
                  label={
                    isPublishing
                      ? isPublishing === "publishing"
                        ? "Publishing"
                        : isPublishing === "scheduling"
                          ? "Scheduling"
                          : "Planning"
                      : selectedSocialNetworks.length > 0
                        ? "Schedule"
                        : "Share"
                  }
                  onClick={() => {
                    if (selectedSocialNetworks.length === 0) {
                      activeTab !== "accounts" && setActiveTab("accounts");
                      toast(
                        "Please select at least one social network first",
                        "info"
                      );
                      setIsMobileSectionsDrawerOpen(true);
                      return;
                    }
                    setIsScheduleModalOpen(true);
                  }}
                  items={[
                    {
                      label: "Publish Now",
                      buttonProps: {
                        type: "secondaryColor",
                      },
                      onClick: () => {
                        if (selectedSocialNetworks.length === 0) {
                          activeTab !== "accounts" && setActiveTab("accounts");
                          toast(
                            "Please select at least one social network first",
                            "info"
                          );
                          return;
                        }
                        handlePublishNow().then((res) => {
                          setSelectedSocialNetworks([]);
                        });
                      },
                    },
                    // {
                    //   label: "Set Reminder",
                    //   buttonProps: {
                    //     type: "secondaryGray",
                    //     disabled: true,
                    //   },
                    //   onClick: () => {
                    //     alert("adsa");
                    //   },
                    // },
                  ]}
                />
              )}
            </div>
            {windowWidth >= 1000 && (
              <>
                <div className="border-l border-gray-300 h-3/4 my-auto mr-2"></div>
                <SButton
                  icon={isSaving ? faRefresh : faX}
                  size="md"
                  isLoading={isSaving}
                  iconProps={{ className: `${isSaving && "animate-spin"}` }}
                  type="secondaryGray"
                  onClick={async () => {
                    setIsSaving(true);
                    await handleClose();
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex h-full w-full rounded-t-[24px] max-md:flex-col max-md:rounded-b-0">
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
            (windowWidth >= 900 ? (
              <div className="w-[60%] h-[100%] bg-white border border-gray-200 rounded-tl-[24px] flex flex-col mb-[20px] max-md:w-full max-md:rounded-tl-[0px] max-md:border-0 max-md:m-0 max-md:rounded-b-0">
                <CreationHeader onClose={handleClose} />
                {currentPlan &&
                  (currentPlan?.data.isTrial ||
                    currentPlan?.data.status === TeamPackageStatus.CANCELED ||
                    currentPlan?.data.status === TeamPackageStatus.PAUSED) && (
                    <BillingInfo className="rounded-none md:px-9" />
                  )}
                <div
                  id="creation-side"
                  className={`${activeTab !== "elements" ? (tabsOverlay ? "py-[20px]" : "py-[20px]") : "py-0"} ${activeTab !== "elements" && "px-[36px] max-md:px-[16px]"}  h-full overflow-hidden relative max-md:p-0`}
                >
                  {tabsOverlay && (
                    <div
                      className={`absolute w-full h-[calc(100%+20px)] top-[-20px] left-0 bg-[rgba(152,152,152,0.8)] z-[100]`}
                    />
                  )}
                  {activeTab === "brief" &&
                    (sessionErrored ? (
                      <SessionErrored />
                    ) : (
                      <Brief setIsUpdatedByCopilot={setIsUpdatedByCopilot} />
                    ))}
                  {activeTab === "accounts" && <Channels />}
                  {activeTab === "media" && <Media />}
                  {activeTab === "elements" && <Frames />}
                </div>
              </div>
            ) : (
              <MobileSectionsDrawer
                setIsUpdatedByCopilot={setIsUpdatedByCopilot}
                onCreationClose={handleClose}
              />
            ))}
          <PreviewSide
            isUpdatedByCopilot={isUpdatedByCopilot}
            setIsUpdatedByCopilot={setIsUpdatedByCopilot}
          />
        </div>
      </Drawer>
    </>
  );
};
export default Creation;
