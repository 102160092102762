import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { NotificationType } from "../../../utils/enums/Enums";
import { ISingleNotification } from "../../../utils/interfaces/INotifications";
import { useNavigate } from "react-router-dom";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import { useEffect, useState } from "react";
import { useGetPostById } from "../../../api/posts/getById";
import { usePutNotification } from "../../../api/notifications/put";
import useNotificationSocketStore from "../../../store/notificationsStore";
import { useBrandStore } from "../../../store/brandStore";

export const useValueByType = () => {
  const navigate = useNavigate();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const { mutateAsync: putNotification } = usePutNotification();
  const { handleOpenPost } = useCreationHelperFunctions();
  const { setNotifications, setPage } = useNotificationSocketStore();
  const [postId, setPostId] = useState<null | number>(null);
  const { data: postById, refetch } = useGetPostById({ postId: postId });
  const { currentBrand } = useBrandStore();

  useEffect(() => {
    if (postId) {
      refetch();
    }
  }, [postId]);

  useEffect(() => {
    if (postById && postId) {
      handleOpenPost({
        postId: postById?.data.id,
        onSocialNetworkSelectorOpen: () => undefined,
      });
    }
    return () => {
      setPostId(null);
    };
  }, [postById]);

  const goToPost = async (e: any, item: ISingleNotification) => {
    if (!currentBrand) return;
    e.stopPropagation();
    await putNotification({
      notificationId: item.id,
      isRead: true,
    });
    setNotifications([]);
    setPage(1);
    if (item.customProperty.organizationId !== currentBrand.id) {
      await switchBrand({ id: item.customProperty.organizationId });
    }
    navigate("/content");
    handleOpenPost({
      postId: item.customProperty.id,
      onSocialNetworkSelectorOpen: () => undefined,
    });
  };
  const linkStyle = "text-brand-700 cursor-pointer underline";

  const getValue = (
    item: ISingleNotification,
    type: NotificationType,
    organizationOrWorkspace: string
  ) => {
    const arr = [
      <>
        {item.customProperty.userName} mentioned you on{" "}
        <span
          onClick={async (e) => {
            if (!currentBrand) return;
            e.stopPropagation();
            await putNotification({
              notificationId: item.id,
              isRead: true,
            });
            setNotifications([]);
            setPage(1);
            if (item.customProperty.organizationId !== currentBrand.id) {
              await switchBrand({ id: item.customProperty.organizationId });
            }
            setPostId(item.customProperty.postId);
          }}
          className={linkStyle}
        >
          post
        </span>
      </>,
      `You are invited in ${organizationOrWorkspace}`,
      <>
        Example post in{" "}
        <span
          onClick={async () => {
            if (!currentBrand) return;
            await putNotification({
              notificationId: item.id,
              isRead: true,
            });
            setNotifications([]);
            setPage(1);
            if (item.customProperty.organizationId !== currentBrand.id) {
              await switchBrand({ id: item.customProperty.organizationId });
            }
            navigate("/content");
          }}
          className={linkStyle}
        >
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        created
      </>,
      <>
        Reminder post{" "}
        <span onClick={(e) => goToPost(e, item)} className={linkStyle}>
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        is ready for publish
      </>,
      <>
        Post draft{" "}
        <span onClick={(e) => goToPost(e, item)} className={linkStyle}>
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        was created
      </>,
      <>
        Post{" "}
        <span onClick={(e) => goToPost(e, item)} className={linkStyle}>
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        was published
      </>,
      <>
        Post{" "}
        <span onClick={(e) => goToPost(e, item)} className={linkStyle}>
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        was scheduled
      </>,
      <>
        Scheduled post{" "}
        <span onClick={(e) => goToPost(e, item)} className={linkStyle}>
          {item.customProperty.name ? item.customProperty.name : "Untitled"}
        </span>{" "}
        was published
      </>,
      <>
        New user joined the{" "}
        <span
          onClick={async (e) => {
            e.stopPropagation();
            await putNotification({
              notificationId: item.id,
              isRead: true,
            });
            switchBrand({ id: item.customProperty.organizationId }).then(
              (res) => {
                navigate("/brand");
              }
            );
          }}
          className={linkStyle}
        >
          brand
        </span>
      </>,
      <>{item.customProperty.message}</>,
    ];
    switch (type) {
      case NotificationType.USERTAGGED:
        return arr[0];
      case NotificationType.INVITATION:
        return arr[1];
      case NotificationType.ExamplePostCreated:
        return arr[2];
      case NotificationType.ReminderPostReadyForPublishing:
        return arr[3];
      case NotificationType.PostDraftWasCreated:
        return arr[4];
      case NotificationType.PostWasPublished:
        return arr[5];
      case NotificationType.PostWasScheduled:
        return arr[6];
      case NotificationType.ScheduledPostWasPublished:
        return arr[7];
      case NotificationType.UserJoinedTheBrand:
        return arr[8];
      case NotificationType.SystemNotification:
        return arr[9];
      case NotificationType.ReminderPostReadyForPublishing:
        return arr[10];
      default:
        return "";
    }
  };

  return { getValue };
};
