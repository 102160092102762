import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateGAClientId } from "../../googleAnalytics/put";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { ITokenVM } from "../../../../utils/interfaces/IToken";
import { ISignInCOE } from "../../../../utils/interfaces/IAuth";
import { makeRequest } from "../../axios";
import { getQuery } from "../../../../hooks/functions/queries";
export const useCanvaSignIn = () => {
  return useMutation<IBaseData<ITokenVM>, any, ISignInCOE>({
    mutationFn: ({ email, password, canvaToken }) =>
      makeRequest.post("/Auth/login", {
        emailAddress: email,
        password: password,
        canvaToken: canvaToken,
      }),
    onSuccess: ({ data }) => {
      localStorage.setItem("token", data.accessToken);
      const state = getQuery("state");
      window.location.replace(
        `https://www.canva.com/apps/configured?success=${true}&state=${state}&isFromCanva=${true}`
      );
    },
  });
};
